export const menu = [
    {
        "title": "Starters",
        "subCategories": null,
        "items": [
            {
                title:'Buffalo Souljas',
                description:'Crispy wontons filled with spicy buffalo chicken and cream cheese (65 g)',
                side:'Served with a side of ranch.',
                price:'145'
            },
            {
                title:'Chicken Wings',
                description:"Your choice of BBQ, spicy BBQ, salt & pepper, honey garlic, habanero-mango, lemon pepper, spicy tamarindo, or chipotle lime (460 g)",
                price:'160'
            },
            {
                title:'Nachos',
                description:'Corn tortilla chips topped with a cheese blend, jalapeños, green onion, black olives, refried beans, and pico de gallo',
                side:'Served with a side of sour cream.',
                addon:'Add Chicken or Arrachera $50 (100g).',
                price:'195'
            },
                {
                title:'Spinach & Artichoke Dip',
                description:'Creamy spinach, artichoke hearts, cream cheese and gouda with a hint of spice Served with warm tortilla chips. ',
                price:'160'
            },
            {
                title:'Breaded Mushrooms',
                description:'These fried mushrooms are coated in a light and crispy, seasoned beer batter then deep fried to golden brown perfection.',
                side: "Served with a Ranch Dill dip.",
                price:'125'
            },
            {
                title:'Popcorn Chicken',
                description:'Bite-sized pieces of seasoned and lightly battered chicken (180 g) ',
                side: "Served with your choice of dipping sauce: honey mustard, BBQ, Buffalo, or Ranch",
                price:'145'
            },
            {
                title:'Cajun Popcorn Shrimp',
                description:'Deep-fried shrimp in a spicy cajun batter (160g)',
                side: "Served with a side of ranch.",
                price:'160'
            },
            {
                title:'Chicken Quesadilla',
                description:'A cheesy quesadilla with seasoned chicken and bell peppers. (100g).',
                side:'Served with a side of sour cream and pico de gallo.',
                price:'155'
            },
            {
                title:'Jax Riblets',
                description:'Ribs served in our signature sauce (160g)',
                price:'155'
            },
            {
                title:'Loaded Potato Skins',
                description:'Crispy potato skins loaded with cheese, bacon and green onion.',
                side:'Served with a side of sour cream.',
                price:'155'
            },
            {
                title:'Deep Fried Pickles',
                description:'Deep fried & dill-icious!',
                price:'145'
            },
            {
                title:'Jax Stix',
                description:'Golden sticks of fried gouda cheese.',
                side:'Served with a side of marinara.',
                price:'155'
            },
            {
                title:'Onion Rings',
                description:"Sliced white onion dusted and fried in our signature seasoning ",
                price:'115'
            },
            {
                title:'Original Fries',
                description:'Golden, crispy and lightly seasoned french fries.',
                addon: "Add gravy $25",
                price:'95'
            },
            {
                title:'Jax Supreme Fries',
                description:'Delicious fries smothered in mozzarella cheese and gravy',
                price:'145'
            },
                {
                title:'Chili Cheese Fries',
                description:'The perfect blend of zesty chili, melted cheese, and french fries (70 g) ',
                price:'155'
            },
            {
                title:'Pulled Pork Fries',
                description:'Slow cooked BBQ pulled pork, mozzarella cheese and gravy (70 g) ',
                price:'165'
            },
        ]
    },
    {
        "title": "Mains",
        "subCategories": null,
        "items": [
            {
                title:'Cheeseburger in Paradise',
                description:'House-made Angus beef patty with cheddar cheese bacon, lettuce, pickles, onions, tomato (180 g) ',
                price:'195'
            },
            {
                title:'Mushroom Swiss Burger',
                description:'Grilled black angus topped with delcious melted Swiss cheese and sautéed mushrooms (180g)',
                price:'195'
            },
            {
                title:'Tequila Lime Chicken Burger',
                description:'Grilled chicken breast with our signature Tequila-Lime glaze, chipotle mayo, lettuce and fresh avocado (180g)',
                price:'190'
            },
                {
                title:'Veggie Burger',
                description:'Plant-based Beyond Burger topped with red onion, lettuce, tomato and avocado (114 g) ',
                price:'180'
            },
            {
                title:'Buffalo Chicken Wrap',
                description:'Grilled Buffalo chicken topped with lettuce, tomato, red onion, and Ranch dressing (120 g) ',
                price:'155'
            },
            {
                title:'Arrachera Salad Wrap',
                description:'Grilled Arrachera steak with lettuce, cucumber, onion, and tomato with a special sauce (120 g)  ',
                price:'155'
            },
                {
                title:'Chicken Caeser Wrap',
                description:'Grilled chicken breast with fresh romaine lettuce, parmesan cheese, chopped croutons, and a creamy caesar dressing.',
                price:'155'
            },
            {
            title:'Beef Dip',
            description:'Slow-roasted, shaved roast beef, provolone cheese, horseradish and a delicious Au Jus (180 g) ',
            price:'195'
        },
            {
            title:'Pizza Sub',
            description:'Toasted roll topped with JAX signature sauce, salami, pepperoni, and mozzarella cheese. ',
            price:'165'
        },
        {
            title:'Jax Dog',
            description:'JAX premium sausage in a freshly toasted bun (45 g) ',
            addon:'Add Cheese $30 | Add bacon $30 (37g)',
            price:'130'
        },
        {
            title:'Texas Chili Dog',
            description:'JAX premium sausage with hearty house-made chili and cheddar cheese (50 g)',
            price:'160'
        },
        {
            title:'Chicken Tenders',
            description:'Juicy & crispy chicken tenders. (220g).',
            side:'Served with your choice of sauce: Ranch, BBQ, Honey Mustard or Buffalo.',
            price:'170'
        },
                {
                title:'Fish & Chips',
                description:'Beer-battered mahi-mahi and crispy french fries. (150 g)',
                side: "Served with house-made tartar sauce",
                price:'190'
            },
            {
                title:'Griilled Tacos',
                description:'Your choice of (3) grilled Shrimp, Arrachera or Chicken filled tacos (240 g).',
                price:'165'
            },
            {
                title:'Sizzling Fajitas',
                description:'Steak, chicken or shrimp fajitas with grilled bell peppers and onions (240g).',
                side: "Served with your choice sides: pico de gallo, sour cream or shredded cheese.",
                price:'245'
            },
            {
                title:'Jax House Salad',
                description:'Romaine lettuce, cucumber, tomato, shaved carrots, dried cranberries, walnuts, feta cheese (* Nut Allergy *)',
                price:'145'
            },
            {
                title:'Caeser Salad',
                description:'Romaine lettuce, shaved parmesan cheese, croutons, and a creamy caesar dressing',
                addon:'Add Chicken $50 (150g)',
                price:'145'
            },
        ]
    },
    {
        "title": "Pizza",
        "subCategories": null,
        "items": [
            {
                title:"Classic Margarita",
                description:"Mozzarella cheese, fresh tomatoes and basil ",
            },
            {
                title:"Say Cheese!",
                description:"A cheesy blend of mozzarella, cheddar, parmesan and gouda",
            },
            {
                title:"Jax Special",
                description:"Ham, pepperoni, red onions, mushrooms, black olives and mozzarella cheese ",
            },
            {
                title:"Heavenly Hawaiian",
                description:"Mozzarella cheese, ham, and fresh pineapple ",
            },
            {
                title:"Vegetarian",
                description:"Mozzarella cheese, fresh mushrooms, black olives, spinach, cherry tomatoes and artichoke hearts ",
            },
            {
                title:"Meat Lovers",
                description:"Ham, pepperoni, salami and Italian sausage",
            },
            {
                title:"Go Canadian!",
                description:"Pepperoni, mushroom, bacon and mozzarella cheese",
            },
            {
                title:"Whiskey BBQ Chicken",
                description:"House-made Whiskey BBQ sauce, chicken, bacon, caramelized red onion and a blend of mozzarella and cheddar cheese",
            },
            {
                title:"Pulled Pork",
                description:"BBQ Sauce base, pulled pork, red onion, fresh pineapple and mozzarella ",
            },
            {
                title:"Bongole",
                description:"Serrano ham, arugula and mozzarella cheese",
            },
            {
                title:"El Paso",
                description:"Seasoned ground beef, mozzarella and cheddar cheese, shredded lettuce, jalapeños, diced tomatoes and cilantro. Drizzled with sour cream.",
            },
            {
                title:"Capricciosa",
                description:"Prosciutto, mushrooms, artichokes, black olives, basil and mozzarella cheese",
            },
            {
                title:"Calzone",
                description:"Our delicious pizza dough stuffed with ham, Italian sausage, mushrooms, spinach, mozzarella cheese, and JAX signature sauce!",
                price: "165"
            },
        ]
    },
    {
        "title": "Drinks",
        "subCategories": [
            {
                "title": "Frozen Drinks & Margaritas",
                "subCategories": [
                    {
                        "title": "Frozen Drinks",
                        "items": [
                            {
                                title:'Pina Colada',
                                description:'Coconut cream & white rum',
                                price:'165'
                            },
                            {
                                title:'Daiquiri',
                                description:'Choose from strawberry, banana or mango with white rum.',
                                price:'165'
                            },
                            {
                                title:'Miami Vice',
                                description:'The perfect pair: Pina Colada & Strawberry Daiquiri',
                                price:'185'
                            },
                            {
                                title:'Blue Hawaii',
                                description:"Rum, Blue Curaçao, pineapple juice and coconut cream",
                                price:'185'
                            },
                            {
                                title:'Bucerias Bulldog',
                                description:'A big taste of Bucerias! Lime margarita with a Coronita (50 ml + 210 ml beer)',
                                price:'185'
                            }
                        ]
                    },
                    {
                        "title": "Margaritas",
                        "items": [
                            {
                                title:'Margarita 30-30',
                                description:'Lime margarita, served frozen or on the rocks.',
                                price:'160 / $180'
                            },
                            {
                                title:'Classic Lime',
                                description:'Lime margarita, served frozen or on the rocks.',
                                price:'125 / $140'
                            },
                            {
                                title:'Mezcal Margarita',
                                description:'Served frozen or on the rocks',
                                price:'185 / $200'
                            },
                            {
                                title:'Cadillac Margarita',
                                description:'1800 Anejo tequilla, Grand Marnier, Cointreau (100 ml)',
                                price:'240'
                            }
                        ]
                    },
                ],
                "items": null
            },
            {
                "title": "Cocktails & Martinis",
                "subCategories": [
                    {
                        "title": "Cocktails",
                        "items": [
                            {
                                title:'Mojito',
                                description:'Rum, club Soda, mint, sugar & lime juice',
                                price:'140'
                            },
                            {
                                title:'Paloma',
                                description:'House tequila, grapefruit soda, lime',
                                price:'120'
                            },
                            {
                                title:'Paralyzer',
                                description:'Vodka, Kahlua, Coke & cream (60 ml)',
                                price:'140'
                            },
                            {
                                title:'Long Island Iced Tea',
                                description:'Vodka, rum, tequila, gin, triple sec, sweet & sour, pineapple juice (100 ml)',
                                price:'225'
                            },
                            {
                                title:'Bahama Mama',
                                description:'White rum, coconut rum, grenadine, orange juice, pineapple juice ',
                                price:'160'
                            },
                            {
                                title:'Caesar',
                                description:'Vodka, Clamato juice and spice',
                                price:'120'
                            },
                            {
                                title:'Hail Jax Caesar',
                                description:'Gin, skewer with pickled carrot, jalapeño and peppered bacon (100 ml)',
                                price:'220'
                            },
                            {
                                title:'Sangria',
                                description:'Red wine & Triple Sec',
                                price:'140'
                            },
                            {
                                title:'Hurricane',
                                description:'Light & dark rum, fruit juices, lime juice & Grenadine (60 ml)',
                                price:'110'
                            },
                            {
                                title:'BJ',
                                description:'Jamesome Irish whiskey and Baileys (100 ml)',
                                price:'225'
                            },
                        ]
                    },
                    {
                        "title": "Martinis",
                        "items": [
                            {
                                title:'The Classic',
                                description:'Gin & dry Vermouth',
                                price:'165'
                            },
                            {
                                title:'Cosmopolitan',
                                description:'Vodka, Controy, cranberry juice and lime.',
                                price:'165'
                            },
                            {
                                title:'Chocolate',
                                description:'Vodka, Baileys & chocolate syrup',
                                price:'185'
                            },
                            {
                                title:'Chocolate Strawberry',
                                description:'Vodka, strawberry cream liqueur and Creme de Cacao',
                                price:'185'
                            },
                            {
                                title:'Mint Chocolate',
                                description:'Vodka, mint, Baileys & chocolate syrup',
                                price:'130'
                            },
                            {
                                title:'Green Apple',
                                description:'Vodka, apple liqueur and Sweet & Sour',
                                price:'185'
                            },
                            {
                                title:'Lemon Drop',
                                description:'Vodka, lemon juice, simple syrup, sugar rim ',
                                price:'185'
                            },
                            {
                                title:'Hypnotic',
                                description:'Vodka, Hypnotic liquer, pineapple juice',
                                price:'225'
                            }
                        ]
                    },
                ],
                "items": null
            },
            {
                "title": "Beer & Wine",
                "subCategories": [
                    {
                        "title": "Beer",
                        "items": [
                            {
                                title:'Bohemia Clara',
                                price:'60'
                            },
                            {
                                title:'Bohemia Obscura',
                                price:'60'
                            },
                            {
                                title:'Corona',
                                price:'50'
                            },
                            {
                                title:'Corona Light',
                                price:'50'
                            },
                            {
                                title:'Corona Zero',
                                price:'50'
                            },
                            {
                                title:'Heineken',
                                price:'70'
                            },
                            {
                                title:'Heineken Zero',
                                price:'50'
                            },
                            {
                                title:'Indio',
                                price:'50'
                            },
                            {
                                title:'Michelob Ultra',
                                price:'60'
                            },
                            {
                                title:'Modelo Especial',
                                price:'60'
                            },
                            {
                                title:'Modelo Negra',
                                price:'60'
                            },
                            {
                                title:'Pacifico',
                                price:'50'
                            },
                            {
                                title:'Pacifico Light',
                                price:'50'
                            },
                            {
                                title:'Stella Artois',
                                price:'70'
                            },
                            {
                                title:'Strongbow Berry',
                                price:'70'
                            },
                            {
                                title:'Strongbow Gold Apple',
                                price:'70'
                            },
                            {
                                title:'Strongbow Honey',
                                price:'70'
                            },
                            {
                                title:'Strongbow Rose',
                                price:'70'
                            },
                            {
                                title:'Tecate',
                                price:'50'
                            },
                            {
                                title:'Tecate Amber',
                                price:'50'
                            },
                            {
                                title:'Victoria',
                                price:'50'
                            },
                            {
                                title:'XX Amber',
                                price:'50'
                            },
                            {
                                title:'XX Lager',
                                price:'50'
                            }
                        ]
                    },
                    {
                        "title": "Wine",
                        "items": [
                            {
                                title:'House Red (6oz)',
                                description:'(6 oz / 177 ml)',
                                price:'130'
                            },
                            {
                                title:'House Red (8oz)',
                                description:'(8 oz / 236 ml)',
                                price:'160'
                            },
                            {
                                title:'House Red (Bottle)',
                                description:'(25 oz / 750 ml)',
                                price:'435'
                            },
                            {
                                title:'House White (6oz)',
                                description:'(6 oz / 177 ml)',
                                price:'130'
                            },
                            {
                                title:'House White (8oz)',
                                description:'(8 oz / 236 ml)',
                                price:'160'
                            },
                            {
                                title:'House White (Bottle)',
                                description:'(25 oz / 750 ml)',
                                price:'435'
                            },
                        ]
                    },
                ],
                "items": null
            },
            {
                "title": "Shots & Liqueurs",
                "subCategories": [
                    {
                        "title": "Shots",
                        "items": [
                            {
                                title:'Chocolate Cake',
                                description:'Vodka, Frangelico, vanilla and a sugar coated lime',
                                price:'140'
                            },
                            {
                                title:'Creamsicle',
                                description:'Orange vodka, orange juice, Triple Sec, whipped cream',
                                price:'140'
                            },
                            {
                                title:'Cinnamon Toast Crunch',
                                description:'Fireball, Rum Chata, Cinnamon sugar rim',
                                price:'140'
                            },
                            {
                                title:'Key Lime Pie',
                                description:'Vodka, Malibu, Carnation, Lime juice',
                                price:'120'
                            },
                            {
                                title:'Marijuana Milkshake',
                                description:'Melon liqueur, Creme de cacao, Milk',
                                price:'120'
                            },
                            {
                                title:'ABC',
                                description:'Amaretto, Baileys, Cognac',
                                price:'225'
                            },
                            {
                                title:'Blow Job',
                                description:'Baileys & Jameson',
                                price:'160'
                            },
                            {
                                title:'Jägerbomb',
                                description:'Red Bull & Jägermeister',
                                price:'140'
                            },
                            {
                                title:'Lemon Drop',
                                description:'Vodka & lemon juice',
                                price:'120'
                            },
                            {
                                title:'Baby Guinness',
                                description:'Kahlua & Baileys',
                                price:'120'
                            },
                            {
                                title:'Dr.Pepper',
                                description:'Amaretto & Coke',
                                price:'120'
                            }
                        ]
                    },
                    //{
                    //    "title": "Vodka",
                    //    "items": [
                    //        {
                    //            title:'Absolut',
                    //            price:'95'
                    //        },
                    //        {
                    //            title:'Absolut Mandarin',
                    //            price:'95'
                    //        },
                    //        {
                    //            title:'Absolut Raspberry',
                    //            price:'95'
                    //        },
                    //        {
                    //            title:'Grey Goose',
                    //            price:'155'
                    //        },
                    //        {
                    //            title:'House Vodka',
                    //            price:'80'
                    //        },
                    //        {
                    //            title:'Kettle One',
                    //            price:'120'
                    //        },
                    //        {
                    //            title:'Skyy Raspberry',
                    //            price:'95'
                    //        },
                    //        {
                    //            title:'Smirnoff',
                    //            price:'95'
                    //        },
                    //        {
                    //            title:'Stolichnaya',
                    //            price:'115'
                    //        },
                    //        {
                    //            title:'Stolichnaya Orange',
                    //            price:'115'
                    //        },
                    //        {
                    //            title:'Titos',
                    //            price:'125'
                    //        }
                    //    ]
                    //},
                    //{
                    //    "title": "Rum",
                    //    "items": [
                    //        {
                    //            title:'Appleton Estate',
                    //            price:'95'
                    //        },
                    //        {
                    //            title:'Appleton Special',
                    //            price:'105'
                    //        },
                    //        {
                    //            title:'Bacardi Anejo',
                    //            price:'105'
                    //        },
                    //        {
                    //            title:'Bacardi Grain Anejo',
                    //            price:'130'
                    //        },
                    //        {
                    //            title:'Bacardi White',
                    //            price:'90'
                    //        },
                    //        {
                    //            title:'Captain Morgan Spiced',
                    //            price:'100'
                    //        },
                    //        {
                    //            title:'Havana 3 Year',
                    //            price:'110'
                    //        },
                    //        {
                    //            title:'Havana 7',
                    //            price:'140'
                    //        },
                    //        {
                    //            title:'Havana Club Special',
                    //            price:'140'
                    //        },
                    //        {
                    //            title:'House Rum',
                    //            price:'80'
                    //        },
                    //        {
                    //            title:'Kraken Black Spiced',
                    //            price:'105'
                    //        },
                    //        {
                    //            title:'Kraken Ghost',
                    //            price:'105'
                    //        },
                    //        {
                    //            title:'Malibu',
                    //            price:'100'
                    //        }
                    //    ]
                    //},
                    //{
                    //    "title": "Tequila",
                    //    "items": [
                    //        {
                    //            title:'1800 Anejo',
                    //            price:'140'
                    //        },
                    //        {
                    //            title:'1800 Blanco',
                    //            price:'110'
                    //        },
                    //        {
                    //            title:'1800 Reposado',
                    //            price:'120'
                    //        },
                    //        {
                    //            title:'7 Leguas',
                    //            price:'120'
                    //        },
                    //        {
                    //            title:'Cazadores',
                    //            price:'100'
                    //        },
                    //        {
                    //            title:'Don Julio 70',
                    //            price:'190'
                    //        },
                    //        {
                    //            title:'Don Julio Anejo',
                    //            price:'140'
                    //        },
                    //        {
                    //            title:'Don Julio Blanco',
                    //            price:'110'
                    //        },
                    //        {
                    //            title:'Don Julio Reposado',
                    //            price:'120'
                    //        },
                    //        {
                    //            title:'El Jimador Reposado',
                    //            price:'100'
                    //        },
                    //        {
                    //            title:'Herradura Reposado',
                    //            price:'130'
                    //        },
                    //        {
                    //            title:'Horonitos Reposado',
                    //            price:'110'
                    //        },
                    //        {
                    //            title:'House Tequila',
                    //            price:'80'
                    //        },
                    //        {
                    //            title:'Jose Cuervo Especial',
                    //            price:'95'
                    //        },
                    //        {
                    //            title:'Negro Zafiro',
                    //            price:'110'
                    //        }
                    //    ]
                    //},
                    //{
                    //    "title": "Mezcal & Raicilla",
                    //    "items": [
                    //        {
                    //            title:'Agua Maldita',
                    //            price:'165'
                    //        },
                    //        {
                    //            title:'Montelobos',
                    //            price:'150'
                    //        },
                    //        {
                    //            title:'Union Mezcal',
                    //            price:'125'
                    //        }
                    //    ]
                    //},
                    //{
                    //    "title": "Whiskey, Scotch & Rye",
                    //    "items": [
                    //        {
                    //            title:'Buchanans Deluxe',
                    //            price:'165'
                    //        },
                    //        {
                    //            title:'Canadian Club',
                    //            price:'120'
                    //        },
                    //        {
                    //            title:'Crown Royal',
                    //            price:'125'
                    //        },
                    //        {
                    //            title:'Chivas Regal',
                    //            price:'145'
                    //        },
                    //        {
                    //            title:'Glenfiddich',
                    //            price:'170'
                    //        },
                    //        {
                    //            title:'House Whiskey',
                    //            price:'80'
                    //        },
                    //        {
                    //            title:'J & B Whiskey',
                    //            price:'90'
                    //        },
                    //        {
                    //            title:'Jack Daniels',
                    //            price:'135'
                    //        },
                    //        {
                    //            title:'Jameson',
                    //            price:'125'
                    //        },
                    //        {
                    //            title:'Jim Beam',
                    //            price:'125'
                    //        },
                    //        {
                    //            title:'Johnnie Walker Red',
                    //            price:'115'
                    //        },
                    //        {
                    //            title:'Johnnie Walker Black',
                    //            price:'165'
                    //        },
                    //        {
                    //            title:'Makers Mark',
                    //            price:'125'
                    //        },
                    //        {
                    //            title:'Martell',
                    //            price:'180'
                    //        },
                    //        {
                    //            title:'Seagrams VO',
                    //            price:'110'
                    //        },
                    //        {
                    //            title:'Southern Comfort',
                    //            price:'110'
                    //        }
                    //    ]
                    //},
                    //{
                    //    "title": "Gin",
                    //    "items": [
                    //        {
                    //            title:'Beefeater',
                    //            price:'110'
                    //        },
                    //                {
                    //            title:'Beefeater Strawberry',
                    //            price:'110'
                    //        },
                    //        {
                    //            title:'Bombay',
                    //            price:'115'
                    //        },
                    //        {
                    //            title:'Diega Rose',
                    //            price:'95'
                    //        },
                    //                {
                    //            title:'Hendricks',
                    //            price:'155'
                    //        },
                    //        {
                    //            title:'House Gin',
                    //            price:'80'
                    //        },
                    //        {
                    //            title:'Larios',
                    //            price:'110'
                    //        },
                    //        {
                    //            title:'Mom Gin',
                    //            price:'140'
                    //        },
                    //        {
                    //            title:'Tanqueray',
                    //            price:'120'
                    //        },
                    //                {
                    //            title:'Tanqueray Flor De Sevilla',
                    //            price:'155'
                    //        }
                    //    ]
                    //},
                    {
                        "title": "Liqueurs",
                        "items": [
                            {
                                title:'Aperol',
                                price:'110'
                            },
                            {
                                title:'Baileys',
                                price:'120'
                            },
                            {
                                title:'Campari',
                                price:'110'
                            },
                            {
                                title:'Controy',
                                price:'110'
                            },
                            {
                                title:'Disaronno',
                                price:'120'
                            },
                            {
                                title:'Drambuie',
                                price:'130'
                            },
                            {
                                title:'Frangelico',
                                price:'140'
                            },
                            {
                                title:'Grand Marnier',
                                price:'140'
                            },
                            {
                                title:'Hypnotic',
                                price:'140'
                            },
                            {
                                title:'Kahlua',
                                price:'110'
                            },
                            {
                                title:'Licor 43',
                                price:'110'
                            },
                            {
                                title:'Rum Chata',
                                price:'110'
                            },
                            {
                                title:'Sambuca Blanco',
                                price:'110'
                            },
                            {
                                title:'Sambuca Negro',
                                price:'110'
                            },
                        ]
                    },
                ],
                "items": null
            },
            {
                "title": "Non-Alcoholic",
                "subCategories": null,
                "items": [
                    {
                        title:'Bottled Water',
                        side:'(600 ml)',
                        price:'40'
                    },
                    {
                        title:'Lemonade',
                        side:'(600 ml)',
                        price:'65'
                    },
                    {
                        title:'Pop',
                        side:'Coke, Diet Coke, Sprite, Fresca, Sprite, Ginger Ale, Fanta Orange (355 ml)',
                        price:'50'
                    },
                    {
                        title:'Red Bull',
                        price:'65'
                    },
                    {
                        title:'Corona Zero',
                        price:'50'
                    },
                    {
                        title:'Heineken Zero',
                        price:'50'
                    },
                ]
            }
        ],
        "items": null
    }
]