export const bands = [
    {
        title: "Group Therapy",
        day: "Monday",
        time: "5:00 PM",
       description: "",
       image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737494026/Jax%20Bucerias/5pm_Group_Therapy_pjoz8n.png"
    },
    {
        title: "Three Circus",
        day: "Monday",
        time: "9:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737494026/Jax%20Bucerias/9pm_Three_Circus_che1k2.png"
    },
    {
        title: "The B34ST",
        day: "Tuesday",
        time: "5:00 PM",
       description: "",
       image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737493931/Jax%20Bucerias/The_B34ST_nwrpe5.png"
    },
    {
        title: "The Stixx",
        day: "Tuesday",
        time: "9:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737493931/Jax%20Bucerias/The_Stixx_lzvwmk.png"
    },
    {
        title: "Los Wendigos",
        day: "Wednesday",
        time: "5:00 PM",
       description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737493923/Jax%20Bucerias/Los_Wendigos_oteq9f.png"
    },
    {
        title: "Monaco",
        day: "Wednesday",
        time: "9:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737493923/Jax%20Bucerias/Monaco_sexai4.png"
    },
    {
        title: "The Osamas",
        day: "Thursday",
        time: "9:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737493946/Jax%20Bucerias/Osamas_cgcwc2.png"
    },
    {
        title: "The Availables",
        day: "Friday",
        time: "5:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737494034/Jax%20Bucerias/Availables_kqis4v.png"
    },
    {
        title: "The IVM",
        day: "Friday",
        time: "9:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737494034/Jax%20Bucerias/IVM_af4bln.png"
    },
    {
        title: "ROAR",
        day: "Saturday",
        time: "5:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737494018/Jax%20Bucerias/Roar_lrkwzj.png"
    },
    {
        title: "The B34ST",
        day: "Saturday",
        time: "9:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737494018/Jax%20Bucerias/The_B34ST_strslf.png"
    },
    {
        title: "Gecko Band",
        day: "Sunday",
        time: "5:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737493957/Jax%20Bucerias/Gecko_Band_z9zjmk.png"
    },
    {
        title: "The Little Walkers",
        day: "Sunday",
        time: "9:00 PM",
        description: "",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737493957/Jax%20Bucerias/Little_Walkers_gp0tnf.png"
    },
]

export const events = [
    {
        title: "Trivia Night!",
        day: "Thursday",
        time: "5:00 PM",
        //description: "Trivia is on PAUSE for the summer - study hard and we will see everyone in the fall for Trivia Night at Jax!",
        description: "Gather the gang and get competitive 5:00PM every Thursday at Jax! Form a team of 6 or less and get ready to battle it out for trivia supremacy and a free round of drinks for the winners!",
        image: "https://res.cloudinary.com/de6ty8wwf/image/upload/v1737494323/Jax%20Bucerias/Trivia_gjxymr.png"
    },
]
