import React from "react"

const Contact = () => {

  return(

<div className="my-4 px-4">
<h1 className="headers text-6xl">
                Contact
            </h1>

    <div className="my-8 flex flex-col md:h-[500px] items-center justify-center p-8 headers text-xl md:text-2xl">
To get in touch, please message us on Facebook.
    </div>
    </div>
    
  )
}

export default Contact

export const Head = () => {
  return(
    <>
      <title>Contact - Jax Bucerias Nayarit</title>
      <meta name="description" content="Jax Bar & Grill in Bucerias, Nayarit, Mexico is home to the best live music in Banderas Bay. With bands on the stage every day of the week, the party never stops at Jax, your #1 destination for entertainment and nightlife in Riviera Nayarit." />
      <meta name="keywords" content="Jax, Bucerias, Nayarit, Nightlife, Live Music, Live Bands, Trivia, Events, Party, Bar, Entertainment, Centro" />
    </>
  )
}